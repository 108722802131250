.print-office-form{
    padding:30px;
    display:none;
}

.grid-form {
    padding:10px;
}

p {
    margin:0;
    padding:0;
    font-size:10px;
}