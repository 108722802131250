.select-label {
    margin:0;
    text-align:left;
    font-size:12px;
    color:rgba(0, 0, 0, 0.54);
}
.non-editable-field {
    text-align:left;
}
.non-editable-field > label{
    font-size:12px;
    color:rgba(0, 0, 0, 0.54);
}

.form-field-generic {

}