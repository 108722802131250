.procedure-code-tbl{
    /*width:1000px;*/
    page-break-before: avoid;
}

.table-text {
    font-size:8px;
}

.table-header-text{
    font-size:10px;
    /*!*width:20px;*!*/
    font-weight:600;
}

@media all {
    .page-break {
        display: none;
    }
}

@media print {
    html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
    .print-office-form{
        display:inherit;
    }
}

@media print {
    .page-break {
        padding:20px;
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }
}

@page {
    size: auto;
    margin: 10mm 6mm;
}